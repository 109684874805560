import React, { useState, useEffect } from 'react'
import { getUserData, logout } from '../utils/auth'
import { FaTwitter, FaGoogle, FaFacebook } from 'react-icons/fa'

const getStrapiUrl = () => {
  return process.env.GATSBY_STRAPI_API_URL || 'http://localhost:1337'
}

const loginTypes = [
  {
    name: 'Google',
    icon: <FaGoogle />,
    url: `${getStrapiUrl()}/connect/google`,
  },
  {
    name: 'Twitter',
    icon: <FaTwitter />,
    disabled: true,
    url: `${getStrapiUrl()}/connect/twitter`,
  },
]

const AuthPage = () => {
  const [user, setUser] = useState('')

  useEffect(() => {
    const userInfo = getUserData()
    if (userInfo) {
      setUser(userInfo.email)
    }
  }, [])

  const openUrl = (url) => {
    window.open(url, 'win', 'width=400,height=500')
    window.setAuthenticated = () => {
      window.location.reload()
    }
  }
  const doLogout = () => {
    logout(() => {
      setUser(null)
    })
  }
  if (user)
    return (
      <>
        <div className="container has-text-centered">
          <div className="column is-6 is-offset-4">
            المستخدم الحالي: {user} &nbsp;
            <button className="button is-light" onClick={doLogout}>
              Logout
            </button>
          </div>
        </div>
      </>
    )
  return (
    <div className="container has-text-centered">
      <div className="columns is-centered">
        <div className="column is-6">
          <h3 className="title has-text-black">تسجيل الدخول</h3>
          <hr className="login-hr" />
          <p className="subtitle has-text-black">
            لتتمكن من اضافة محتويات للموقع، الرجاء تسجيل الدخول باحدى هذه
            الخدمات:{' '}
          </p>

          {loginTypes.map((type) => {
            return (
              <button
                style={{ direction: 'ltr', margin: 5 }}
                disabled={type.disabled}
                key={type.name}
                onClick={() => openUrl(type.url)}
                className="button is-large"
              >
                {/* <span>{type.name}</span> */}
                <span className="icon">{type.icon}</span>
              </button>
            )
          })}
          <hr />
          <p className="content is-small">
            تسجيل الدخول يتم عن طريق طرف ثالث - لا تحفظ بيانات الدخول على موقع
            المركز نهائيا
          </p>
        </div>
      </div>
    </div>
  )
}
export default AuthPage
